import datum from "../images/datum.jpg"
const INFO = {
	main: {
		title: "Saphal Pathak",
		name: "Saphal Pathak",
		email: "saphalpathak3@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/saphalpathak",
		linkedin: "https://www.linkedin.com/in/saphalpathak/",
		instagram: "https://www.instagram.com/saphal_pathak/",
	},

	homepage: {
		title: "Java Developer",
		description:
			"I am a backend developer with expertise in Java Spring Boot. I have experience in building scalable, secure, and reliable web applications using various Spring Boot frameworks and technologies. I enjoy solving complex problems and learning new skills in the Java ecosystem. I am passionate about creating high-quality code that adheres to best practices and industry standards in Java development. I am always seeking new challenges and opportunities to grow as a developer, particularly in the context of Java Spring Boot.",
	},

	about: {
		title: "I’m Saphal Pathak",
		description: `I have worked on a variety of Spring Boot projects over the years, including government projects, mobile finance systems, and anti-money laundering solutions. I take pride in the progress I've made and the high-quality, scalable applications I've developed using Spring Boot.
			I am always open to new ideas and innovations, especially in the context of Spring Boot development. If you have any thoughts on how to enhance or improve any of the Spring Boot projects I've worked on, I would be more than happy to discuss and learn from your expertise.
			
			In summary, I have significant experience in Spring Boot and have been involved in diverse projects, including government, mobile finance, and anti-money laundering domains. Although my projects are not open-source, I am eager to collaborate and welcome any suggestions or feedback to further improve my work.`,
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Datum (TAML)",
			description:
				`The Anti Money Laundering and Transaction Management (TAML)
				  was a robust system designed to detect
				 and prevent financial crimes. It employed advanced algorithms and data analytics to identify suspicious transactions,
				  ensuring compliance with regulatory requirements. The platform provided real-time monitoring and reporting capabilities,
				   enabling financial institutions to mitigate risks and maintain regulatory compliance efficiently. This project played a
				    pivotal role in safeguarding the integrity of the financial system while improving operational efficiency for our clients.`,
			logo: datum,
			linkText: "View Project",
			hasLink: false,
		},

		{
			title: "Subisu Middleware",
			description: `Subisu revolutionizes WiFi management and payments, offering real-time network insights for optimized performance.
			Users can effortlessly access vital network details and make payments with convenience. This integrated platform streamlines billing,
			providing a hassle-free experience. It's a game-changer for enhancing digital connectivity and user satisfaction.`,
			logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c7/Subisu_Logo.svg/1280px-Subisu_Logo.svg.png",
			linkText: "View Project",
			link: "https://github.com",
			hasLink: false,
		},

		{
			title: "Mofin",
			description:
				`The Mobile Finance was a groundbreaking initiative aimed at expanding financial inclusion through digital channels.
				 It provided users with secure and user-friendly mobile banking and payment solutions, enabling them to access financial services anytime,
				  anywhere. The project integrated mobile wallets, peer-to-peer transfers, and bill payments, offering a comprehensive financial ecosystem.
				   This innovative platform empowered underserved populations to participate in the formal financial sector, fostering economic growth and
				    financial stability.`,
			logo: "https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/bb/fe/b1/bbfeb146-5039-d3c5-376d-379599ea9c71/AppIcon-0-0-1x_U007emarketing-0-7-0-0-85-220.png/230x0w.webp",
			linkText: "View Project",
			link: "https://github.com",
			hasLink: false,
		},

		{
			title: "Book Rental System",
			description:
				`The Book Rental System was a convenient and cost-effective solution for avid readers and students.
				 It allowed users to browse, rent, and return books online, eliminating the need for physical visits to libraries.
				  The platform featured an extensive catalog, user-friendly interfaces, and secure payment options.
				   Users could enjoy the flexibility of choosing rental durations, making it an ideal resource for
				    both short-term research and leisure reading. This project enhanced access to knowledge and promoted sustainable reading practices.`,
			logo: "https://upload.wikimedia.org/wikipedia/en/thumb/3/30/Java_programming_language_logo.svg/800px-Java_programming_language_logo.svg.png",
			linkText: "View Project",
			link: "https://github.com/saphalpathak/Book-Rental-System",
			hasLink: true,
		},

		{
			title: "Personal Assistant",
			description:
				`I worked on a project that has three key features: a Todo App for task management,
				 a Financial Transaction History and Chart tool for financial tracking, and real-time weather information.
				  This all-in-one platform aimed to simplify users' daily lives by providing productivity, financial management,
				   and weather awareness in a user-friendly interface.`,
			logo: "https://upload.wikimedia.org/wikipedia/en/thumb/3/30/Java_programming_language_logo.svg/800px-Java_programming_language_logo.svg.png",
			linkText: "View Project",
			link: "https://github.com/saphalpathak/Personal-Assistant",
			hasLink: true,
		},
	],
};

export default INFO;
